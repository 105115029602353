body {
  /* Location of the image */
  background-image: url("../images/povlja-background-cover.jpg");

  /* Background image is centered vertically and horizontally at all times */
  background-position: center center;

  /* Background image doesn't tile */
  background-repeat: no-repeat;

  /* Background image is fixed in the viewport so that it doesn't move when
     the content's height is greater than the image's height */
  background-attachment: fixed;

  /* This is what makes the background image rescale based
     on the container's size */
  background-size: cover;

  /* Set a background color that will be displayed
     while the background image is loading */
  background-color: #464646;

  font-family: 'Muli', sans-serif;
  color: #fff;
}
.text-shadow {
  text-shadow: 0 0 10px #000;
}
a, a:hover, a:active {
    text-decoration: none;
}
a.link-secondary, a.link-secondary:visited {
  color: #FFFFFF;
}
a.link-secondary:hover, a.link-secondary:active {
  color: #868686;
}
h1, .h1, h2, h3, h4, h5, .h6 {
    font-family: 'Playfair Display', serif;
}
h4 {
    font-size: 1.38em;
}
h6 {
    font-weight: 700;
    font-size: 0.9em;
}
.line-header > :first-child::after, .line-header > :first-child::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 66px;
    height: 1px;
    background-color: #fff;
    top: 17px;
}
.line-header > :first-child::after {
    right: -84px;
}
.line-header > :first-child::before {
    left: -84px;
}
.line-header > :first-child {
    position: relative;
    display: inline-block;
}

.dark-line-header > :first-child::after, .dark-line-header > :first-child::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 66px;
  height: 1px;
  background-color: #000;
  top: 17px;
}
.dark-line-header > :first-child::after {
  right: -84px;
}
.dark-line-header > :first-child::before {
  left: -84px;
}
.dark-line-header > :first-child {
  position: relative;
  display: inline-block;
}
.stars i {
    font-size: 0.8em;
}
.bg-gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,25758e+0,7d909f+52,5b838f+100 */
    background: #1e5799; /* Old browsers */
    background: -moz-linear-gradient(left,  #25758e 0%, #7d909f 52%, #5b838f 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #25758e 0%,#7d909f 52%,#5b838f 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #25758e 0%,#7d909f 52%,#5b838f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e5799', endColorstr='#5b838f',GradientType=1 ); /* IE6-9 */
}
footer {
    /* Location of the image */
    background-image: url("../images/footer-cover.jpg");

    /* Background image is centered vertically and horizontally at all times */
    background-position: top center;

    /* Background image doesn't tile */
    background-repeat: no-repeat;

    /* Background image is fixed in the viewport so that it doesn't move when
       the content's height is greater than the image's height */
    background-attachment: fixed;

    /* This is what makes the background image rescale based
       on the container's size */
    background-size: cover;

    /* Set a background color that will be displayed
       while the background image is loading */
    background-color: #464646;

    font-size: 0.8em;

    ul {
        padding-left: 0;
        list-style-type: none;
        text-align: right;
        li {
          display: inline-block;
          border-right: 1px solid #fff;
          a {
            display: inline;
            padding: 0 10px;
            color: #fff;
          }
          &:last-child {
            border-right: none;
          }
      }
    }

    a {
      color: #fff;
      &:hover {
        color: #17a2b8;
      }
    }
}
.logo {
    //color: #252931;
    color: #fff;
    font-weight: 700;
    line-height: 0.9em;
    font-size: 1.4em;
    span {
        font-weight: 400;
        font-size: 0.63em;
    }
}

.welcome-screen {
    min-height: 100vh;
}

.welcome-screen-slider {
    width: 100%;
    text-align: center;
}
.slick-dots li button::before {
  color: #fff !important;
}
.slick-dots li.slick-active button::before {
  color: white !important;
  opacity: 1 !important;
}
.link-down {
  position: absolute;
  display: inline-block;
  border: 1px solid #fff;
  width: 40px;
  height: 40px;
  line-height: 33px;
  bottom: 60px;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  text-align: center;
}
.link-down::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-transition: all .4s cubic-bezier(.77,0,.175,1) 0ms;
  transition: all .4s cubic-bezier(.77,0,.175,1) 0ms;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  z-index: -1;
}

.feature-title {
    color: #999;
    font-size: 0.8em;
    letter-spacing: 1px;
    line-height: 1em;
    padding-bottom: 5px;
    text-transform: uppercase;
}
.feature-time {
    font-size: 2em;
    font-weight: 500;
}

.navigation {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    .book-now {
        width: 70px;
        height: 70px;
        line-height: 70px;
        position: absolute;
        top: 60px;
        right: 0;
        background: #23272b;
        color: white;
        border: none;
        text-align: center;
    }
}

/* HAMBURGER MENU */
$hamburger-padding-x           : 15px !default;
$hamburger-padding-y           : 15px !default;
$hamburger-layer-width         : 40px !default;
$hamburger-layer-height        : 4px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : #000 !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
        3dx,
        3dx-r,
        3dy,
        3dy-r,
        3dxy,
        3dxy-r,
        arrow,
        arrow-r,
        arrowalt,
        arrowalt-r,
        arrowturn,
        arrowturn-r,
        boring,
        collapse,
        collapse-r,
        elastic,
        elastic-r,
        emphatic,
        emphatic-r,
        minus,
        slider,
        slider-r,
        spin,
        spin-r,
        spring,
        spring-r,
        stand,
        stand-r,
        squeeze,
        vortex,
        vortex-r
) !default;

.hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: inline-block;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {
        @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
        }
        @else {
            opacity: $hamburger-hover-opacity;
        }
    }

    &.is-active {
        &:hover {
            @if $hamburger-hover-use-filter == true {
                filter: $hamburger-active-hover-filter;
            }
            @else {
                opacity: $hamburger-active-hover-opacity;
            }
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: #FFFFFF;
        }
    }
}

.hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
}
/*
   * Spring
   */
.hamburger--spring {
    .hamburger-inner {
        top: $hamburger-layer-height / 2;
        transition: background-color 0s 0.13s linear;

        &::before {
            top: $hamburger-layer-height + $hamburger-layer-spacing;
            transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        &::after {
            top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
            transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    &.is-active {
        .hamburger-inner {
            transition-delay: 0.22s;
            background-color: transparent !important;

            &::before {
                top: 0;
                transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(45deg);
            }

            &::after {
                top: 0;
                transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(-45deg);
            }
        }
    }
}


/* The side navigation menu */
.sidenav {

}

/* The navigation menu links */
.sidenav{

    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    right: 0;
    background-color: #23272b; /* Background dark */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */

    &.sidenav-open {
        width: 240px;
    }
    a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
        transition: 0.3s;
    }
    a:hover {
        color: #f1f1f1;
    }
}


/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: margin-left .5s;
    padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

.icons {
  width: 30px;
}
.card {
  border: 1px solid #eee;
}
.room-types .sep {
  margin: 3px 10px;
  width: 5px;
  height: 5px;
  display: inline-block;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #17a2b8;
  border-radius: 50%;
}
.line::before {
  content: '';
  background: #eee;
  height: 1px;
  width: 40px;
  display: block;
  margin-bottom: 16px;
}
.text-light {
  color: #ccc !important;
}
.even-odd {
  p:nth-child(even) {
    background: #eee;
  }
}
label {
  margin-right: 15px;
  color: #6c757d;
}

.cryptedmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}
